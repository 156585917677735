.lang-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.lang {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 10px;
}

.lang-link-button:hover {
  text-decoration: underline;
}

.hide {
  display: none;
}