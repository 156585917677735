.instagram-content {
  margin-top: 0px;
  text-align: center;
  font-size: 15px;
  padding: 15px 15px;
}

.instagram-content a {
  color: Black;;
  text-decoration: none;
}

.instagram {
  display: inline-block;
  vertical-align: top;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  background: url("../../../public/instagram.svg");
  background-size: 20px 20px;
}

@media only screen and (max-width: 1000px) {
  .instagram-content {
    margin-top: 0;
    text-align: center;
    font-size: 15px;
    padding: 10px 15px;
  }
}